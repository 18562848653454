import React, { useMemo } from 'react';
import { GridContainer } from '@components/GeotabGridComponents';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { LazyLoadContainer } from '@web-for-marketing/react-ui';
import { customFormStyles } from './styleObjects/CustomFormBuilderStyles';
import { CustomFormContent } from './helpers/CustomFormContent';
import { ClassNames } from '@emotion/react';
import { CustomFormSectionData } from '@models/section/customFormSection';
import { Grid } from '@mui/material';

interface CustomFormProps {
    sectionData: CustomFormSectionData;
    lazyLoad?: boolean;
    embed?: boolean;
    fieldSpacing?: number;
    headerSection?: boolean;
}

const classes = customFormStyles;

export default function CustomForm({
    sectionData,
    lazyLoad,
    embed,
    fieldSpacing,
    headerSection = false,
}: CustomFormProps): JSX.Element {
    const { enableBackground, backgroundImage } = sectionData.formSettings;
    const { prefixStringWithBaseUrl } = useStaticValues();
    const imageUrl = useMemo(
        () => (backgroundImage ? prefixStringWithBaseUrl(backgroundImage) : ''),
        [backgroundImage, prefixStringWithBaseUrl]
    );
    const formContentFieldSpacing = fieldSpacing ? fieldSpacing : embed ? 1 : 2;

    return (
        <ClassNames>
            {({ css }) => (
                <LazyLoadContainer
                    component={Grid}
                    lazyload={lazyLoad}
                    lazyClass={css(classes.formWithBackgroundImage(imageUrl, enableBackground))}
                    css={classes.defaultFormBackground(enableBackground)}
                    data-testid='customFormBackground'
                >
                    {embed ? (
                        <CustomFormContent
                            fieldSpacing={formContentFieldSpacing}
                            sectionData={sectionData}
                            headerSection={headerSection}
                        />
                    ) : (
                        <GridContainer container css={classes.formContainer} data-testid='customFormSectionContainer'>
                            <CustomFormContent
                                fieldSpacing={formContentFieldSpacing}
                                sectionData={sectionData}
                                headerSection={headerSection}
                            />
                        </GridContainer>
                    )}
                </LazyLoadContainer>
            )}
        </ClassNames>
    );
}
