/* eslint-disable @typescript-eslint/no-explicit-any */
import { trimObjectValues } from '@helpers/objectHelper';
import { updateGeotabSourceCookie } from '@helpers/geotabSourceCookieHelper';
import { httpPost } from './apiService';

export async function postMarketingCloudForm(
    formId: string,
    jsonData: Record<string, any>,
    recaptchaToken: string
): Promise<void> {
    const modifiedFormValues: Record<string, any> = { ...trimObjectValues(jsonData) };

    modifiedFormValues.recaptchaToken = recaptchaToken;

    if (formId === 'generic-lead-form-test' || formId === 'generic-lead-form') {
        const geotabSourceCookieValue = updateGeotabSourceCookie();

        modifiedFormValues['Website_Cookie__c'] = geotabSourceCookieValue
            ? Object.keys(geotabSourceCookieValue)
                .map((key) => `${key}:${geotabSourceCookieValue[key as keyof typeof geotabSourceCookieValue]}`)
                .join()
            : '';
    }

    await httpPost(`/api/marketingcloud/${formId}/`, JSON.stringify(modifiedFormValues));
}
