import { useMemo } from 'react';
import { SelectOption } from '@web-for-marketing/react-ui';
import { useLazyLoadedSelectOptions, LazyLoadedSelectOptionsTypes } from '@hooks/useLazyLoadedSelectOptions';
import { clientTypeOptions } from '@helpers/selectOptions/clientTypeOptions';
import { fleetSizeOptions } from '@helpers/selectOptions/fleetSizeOptions';
import { interestOptions } from '@helpers/selectOptions/interestOptions';
import { DropdownType } from '@models/customForm';
import { FormComponent, FormBuilderDropdown } from '@models/customForm';
import { convertToSelectOption } from '@helpers/selectOptions/convertOptions';

type PredefinedDropdowns = Record<string, SelectOption[]>;

const selectOptions: LazyLoadedSelectOptionsTypes[] = [
    DropdownType.Country,
    DropdownType.CanadianProvince,
    DropdownType.AmericanState,
    DropdownType.Title,
    DropdownType.Industry,
    DropdownType.AreaOfInterest2,
    DropdownType.AdminService,
    DropdownType.Commerce,
    DropdownType.Construction,
    DropdownType.Education,
    DropdownType.Entertainment,
    DropdownType.Finance,
    DropdownType.Government,
    DropdownType.Healthcare,
    DropdownType.Hospitality,
    DropdownType.Transportation,
    DropdownType.Utilities,
    DropdownType.BrazilianState,
];

function getDropdownOptions(dropdown: FormBuilderDropdown, predefinedDropdowns: PredefinedDropdowns): SelectOption[] {
    if (dropdown.type === DropdownType.Custom || dropdown.customOptions) {
        if (dropdown.options) {
            return dropdown.options.map((option) => ({
                content: option.name,
                value: dropdown.sameOptionKeyValuePair ? option.name : option.value,
            }));
        } else {
            return [];
        }
    } else {
        return predefinedDropdowns[dropdown.type] || [];
    }
}

export function useCustomFormDropdownOptions(component: FormComponent): SelectOption[] {
    const dropdownType = component.name === 'dropdown' ? component.type : undefined;
    const optionsToLoad = useMemo(() => selectOptions.filter((option) => option === dropdownType), [dropdownType]);
    const [options] = useLazyLoadedSelectOptions(optionsToLoad);

    const predefinedDropdowns: PredefinedDropdowns = useMemo(
        () => ({
            ...(dropdownType ? { [dropdownType]: options } : {}),
            [DropdownType.FleetSize]: convertToSelectOption(fleetSizeOptions),
            [DropdownType.ClientType]: convertToSelectOption(clientTypeOptions),
            [DropdownType.AreaOfInterest]: convertToSelectOption(interestOptions),
        }),
        [options, dropdownType]
    );

    const dropdownOptions: SelectOption[] | string[] = useMemo(
        () => (component.name === 'dropdown' ? getDropdownOptions(component, predefinedDropdowns) : []),
        [component, predefinedDropdowns]
    );

    return dropdownOptions;
}
