import React, { useMemo } from 'react';
import { createCustomFormStyles } from '@components/SectionComponents/styleObjects/CreateCustomFormStyles';
import { Grid } from '@mui/material';
import { FormComponent } from '@models/customForm';
import { getMultiSelectArray } from '@components/EditorFormComponents/helpers/customFormHelpers';
import {
    Checkbox,
    Heading,
    Text,
    Textarea,
    Select,
    SelectOption,
    RadioGroup,
    Input,
} from '@web-for-marketing/react-ui';
import { ParseAbbreviations } from '@components/ParseAbbreviations';
import { getTranslation } from '@helpers/languageTranslations';
import { useTranslation } from '@stateManagement/TranslationContext';
import { splitPaddingValues } from '@components/SectionComponents/helpers/spacingStyleHelpers';
import { getRgbaColor, v2Colors } from '@web-for-marketing/react-ui';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { useCustomFormDropdownOptions } from '@hooks/useCustomFormDropdownOptions';
import { getRandomString } from '@helpers/string';

interface CreateCustomFormProps {
    enableBackground: boolean;
    component: FormComponent;
    formValues: { [x: string]: string | boolean | number };
    updateFieldValue: (component: FormComponent, value: string | boolean | number) => void;
    handleMultiSelectChange: (component: FormComponent, valueArray: string[]) => void;
    enableRecaptcha: () => void;
    disableAutoFill?: boolean;
}

function createForm(
    enableBackground: boolean,
    component: FormComponent,
    formValues: { [x: string]: string | number | boolean },
    formTranslations: { [x: string]: string },
    updateFieldValue: (component: FormComponent, value: string | boolean | number) => void,
    enableRecaptcha: () => void,
    isMobile: boolean,
    dropdownOptions: SelectOption[],
    handleMultiSelectChange: (component: FormComponent, valueArray: string[]) => void,
    disableAutoFill: boolean
): JSX.Element | undefined {
    // id is used by user-agents to provide form autocompletion values, so it is randomly generated to disable autocomplete
    // a random autoComplete value disables Chrome's autofill functionality
    const autoFillProps = disableAutoFill
        ? { id: getRandomString(), autoComplete: 'disableAutoFillUnique' }
        : { id: component.fieldId };

    switch (component.name) {
        case 'textfield':
            return (
                <Grid item sm={12} md={6} lg={component.breakpoint} display={component.hidden ? 'none' : undefined}>
                    <Input
                        {...autoFillProps}
                        required={component.required}
                        label={component.label}
                        shrinkLabel={!enableBackground}
                        placeholder={component.placeholder}
                        type={component.type}
                        value={(formValues[component.fieldId] as string) || ''}
                        onFocus={enableRecaptcha}
                        onChange={(event) => updateFieldValue(component, event.target.value)}
                        labelColor={enableBackground ? v2Colors.core.snow : undefined}
                        color={enableBackground ? v2Colors.core.snow : undefined}
                        borderColor={enableBackground ? v2Colors.core.snow : undefined}
                        backgroundColor={enableBackground ? getRgbaColor(v2Colors.core.geotab, 0.4) : undefined}
                        weight='regular'
                    />
                </Grid>
            );
        case 'dropdown':
            if (component.selectType === 'single') {
                return (
                    <Grid item sm={12} md={6} lg={component.breakpoint}>
                        <Select
                            {...autoFillProps}
                            label={component.label}
                            placeholder={component.placeholder}
                            shrinkLabel={!enableBackground}
                            required={component.required}
                            native={isMobile}
                            labelColor={enableBackground ? v2Colors.core.snow : undefined}
                            color={enableBackground ? v2Colors.core.snow : undefined}
                            borderColor={enableBackground ? v2Colors.core.snow : undefined}
                            backgroundColor={enableBackground ? getRgbaColor(v2Colors.core.geotab, 0.4) : undefined}
                            weight='regular'
                            options={dropdownOptions ?? []}
                            value={(dropdownOptions.length && (formValues[component.fieldId] as string)) || ''}
                            onChange={(value) => updateFieldValue(component, value)}
                            onFocus={enableRecaptcha}
                        />
                    </Grid>
                );
            } else if (component.selectType === 'multiple') {
                const multiSelectValue = formValues[component.fieldId]
                    ? getMultiSelectArray(formValues[component.fieldId] as string)
                    : [];
                return (
                    <Grid item sm={12} md={6} lg={component.breakpoint}>
                        <Select
                            {...autoFillProps}
                            label={component.label}
                            placeholder={component.placeholder}
                            shrinkLabel={!enableBackground}
                            required={component.required}
                            native={isMobile}
                            multiple
                            labelColor={enableBackground ? v2Colors.core.snow : undefined}
                            color={enableBackground ? v2Colors.core.snow : undefined}
                            borderColor={enableBackground ? v2Colors.core.snow : undefined}
                            backgroundColor={enableBackground ? getRgbaColor(v2Colors.core.geotab, 0.4) : undefined}
                            weight='regular'
                            options={dropdownOptions ?? []}
                            value={multiSelectValue}
                            onChange={(value) => handleMultiSelectChange(component, value)}
                            onFocus={enableRecaptcha}
                            helperText={
                                (component.forceMultiPick ? multiSelectValue.length == 1 : false)
                                    ? formTranslations.forceMultiPick
                                    : undefined
                            }
                            helperTextColor={v2Colors.core.ember}
                        />
                    </Grid>
                );
            }
            break;
        case 'checkbox':
            return (
                <div css={{ width: '100%' }}>
                    <Checkbox
                        id={component.fieldId}
                        checkedColor={enableBackground ? v2Colors.core.snow : undefined}
                        uncheckedColor={enableBackground ? v2Colors.core.snow : undefined}
                        checked={!!formValues[component.fieldId]}
                        value={formValues[component.fieldId]}
                        onFocus={enableRecaptcha}
                        onChange={(event) => updateFieldValue(component, event.target.checked)}
                        label={
                            <Grid item sm={10}>
                                <Text
                                    variant='body2'
                                    css={classes.linkText(enableBackground)}
                                    color={enableBackground ? 'inversePrimary' : 'tertiary'}
                                    dangerouslySetInnerHTML={{
                                        __html: ParseAbbreviations({
                                            content: component.message,
                                            stringFormat: true,
                                        }),
                                    }}
                                />
                            </Grid>
                        }
                        css={{ alignItems: 'flex-start' }}
                    />
                    {component.checkedToProceed && !formValues[component.fieldId] ? (
                        <Text variant='body2' css={classes.warningText}>
                            {formTranslations.checkedToProceed}
                        </Text>
                    ) : null}
                </div>
            );
        case 'radiobutton':
            return (
                <div css={{ width: '100%' }}>
                    <RadioGroup
                        id={component.fieldId}
                        row
                        value={formValues[component.fieldId] ? '1' : '0'}
                        onFocus={enableRecaptcha}
                        onChange={(event) => updateFieldValue(component, event.target.value === '1')}
                        options={[
                            { content: formTranslations.yes, value: '1' },
                            { content: formTranslations.no, value: '0' },
                        ]}
                        label={
                            <Text
                                variant='body2'
                                color={enableBackground ? 'inversePrimary' : 'tertiary'}
                                dangerouslySetInnerHTML={{
                                    __html: ParseAbbreviations({
                                        content: component.message,
                                        stringFormat: true,
                                    }),
                                }}
                            />
                        }
                        checkedColor={enableBackground ? v2Colors.core.snow : undefined}
                        uncheckedColor={enableBackground ? v2Colors.core.snow : undefined}
                        contentColor={enableBackground ? v2Colors.core.snow : undefined}
                    />
                    {component.yesToProceed && !formValues[component.fieldId] ? (
                        <Text variant='body2' css={classes.warningText}>
                            {formTranslations.yesToProceed}
                        </Text>
                    ) : null}
                </div>
            );
        case 'textarea':
            return (
                <Grid item sm={12} md={12} lg={component.breakpoint}>
                    <Textarea
                        {...autoFillProps}
                        shrinkLabel={!enableBackground ? true : false}
                        weight='regular'
                        backgroundColor={enableBackground ? getRgbaColor(v2Colors.core.geotab, 0.4) : undefined}
                        color={enableBackground ? v2Colors.core.snow : undefined}
                        labelColor={enableBackground ? v2Colors.core.snow : undefined}
                        borderColor={enableBackground ? v2Colors.core.snow : undefined}
                        required={component.required}
                        rows={component.numberOfRows}
                        label={component.label}
                        placeholder={component.placeholder}
                        value={(formValues[component.fieldId] as string) || ''}
                        onFocus={enableRecaptcha}
                        onChange={(event) => updateFieldValue(component, event.target.value)}
                    />
                </Grid>
            );
        case 'heading':
            switch (component.type) {
                case 'title':
                    return component.content ? (
                        <Grid item sm={12} css={classes.mainContainer(splitPaddingValues(component.spacing))}>
                            <Heading
                                variant='h2'
                                align={component.align}
                                weight={component.weight}
                                italic={component.italic}
                                color={enableBackground ? 'inversePrimary' : 'primary'}
                            >
                                {component.content}
                            </Heading>
                        </Grid>
                    ) : undefined;
                case 'subtitle':
                case 'text':
                    return component.content ? (
                        <Grid item sm={12} css={classes.mainContainer(splitPaddingValues(component.spacing))}>
                            <Text
                                variant={component.type === 'subtitle' ? 'body1' : 'body2'}
                                align={component.align}
                                weight={component.weight}
                                italic={component.italic}
                                color={
                                    enableBackground
                                        ? 'inversePrimary'
                                        : component.type === 'subtitle'
                                            ? 'primary'
                                            : 'tertiary'
                                }
                            >
                                {component.content}
                            </Text>
                        </Grid>
                    ) : undefined;
                default:
                    break;
            }
            break;
        default:
            break;
    }
}

const classes = createCustomFormStyles;

export function CreateCustomForm({
    enableBackground,
    component,
    formValues,
    updateFieldValue,
    enableRecaptcha,
    handleMultiSelectChange,
    disableAutoFill,
}: CreateCustomFormProps): JSX.Element {
    const { selectedLanguageTranslation } = useTranslation();
    const [isMobile] = useAtom(isMobileAtom);
    const dropdownOptions = useCustomFormDropdownOptions(component);
    const formTranslations = useMemo(() => {
        return getTranslation(selectedLanguageTranslation, 'formTranslations');
    }, [selectedLanguageTranslation]);

    return (
        <>
            {createForm(
                enableBackground,
                component,
                formValues,
                formTranslations,
                updateFieldValue,
                enableRecaptcha,
                isMobile,
                dropdownOptions,
                handleMultiSelectChange,
                !!disableAutoFill
            )}
        </>
    );
}
