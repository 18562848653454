export const interestOptions: string[] = [
    'Driving Behavior & Safety',
    'Engine Diagnostics & Maintenance',
    'Open Architecture & Software Integration',
    'Vehicle Location & Historical Tracking',
    'HOS/ELD Compliance',
    'Reporting',
    'OEM Integrated Solution',
    'Fuel Management',
    'Routing and Dispatching',
    'Sustainable Fleet Solutions',
    'Fleet Electrification',
];
