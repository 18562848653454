import React, { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { selectedLanguageAtom } from '@atoms/appSettings';
import { ChildContainer } from '@components/GeotabGridComponents';
import { getTranslation } from '@helpers/languageTranslations';
import { Button, Grid } from '@mui/material';
import { useTranslation } from '@stateManagement/TranslationContext';
import { Heading, Text } from '@web-for-marketing/react-ui';
import { useAtom } from 'jotai';
import { customFormStyles } from '../styleObjects/CustomFormBuilderStyles';
import { redirectToThankyou } from '@helpers/form';
import { useUTMFormValues } from '@hooks/useUTMFormValues';
import { NotificationContext } from '@contexts/NotificationContext';
import { postMarketingCloudForm } from '@services/marketingCloudPublicService';
import { useRecaptcha } from '@web-for-marketing/react-ui';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { CreateCustomForm } from './CreateCustomForm';
import {
    shouldDisableSubmit,
    getInitialFormValues,
    getMultiSelectArray,
    submitValidation,
} from '@components/EditorFormComponents/helpers/customFormHelpers';
import { CustomFormSectionData } from '@models/section/customFormSection';
import { FormComponent } from '@models/customForm';
import { logError } from '@helpers/errors';
import { DataLayerFormValue } from '@models/dataLayer';

interface CustomFormContentProps {
    sectionData: CustomFormSectionData;
    fieldSpacing: number;
    headerSection: boolean;
}

const classes = customFormStyles;

export function CustomFormContent({ sectionData, fieldSpacing, headerSection }: CustomFormContentProps): JSX.Element {
    const { title, subTitle, formSettings, submitButton, components } = sectionData;
    const { formId, returnUrl, htmlFormId, enableBackground, disableAutoFill } = formSettings;
    const { recaptchaSiteKey } = useStaticValues();
    const [enableRecaptcha, RecaptchaComponent, token, refresh] = useRecaptcha(recaptchaSiteKey);
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const { selectedLanguageTranslation } = useTranslation();
    const [dataLayerFormValues, setDataLayerFormValues] = useState<DataLayerFormValue[]>([]);

    const pageLanguageCode = useMemo(() => {
        return getTranslation(selectedLanguageTranslation, 'salesforce');
    }, [selectedLanguageTranslation]);

    /* eslint-disable camelcase */
    const initialFormValues = {
        Form_Language_Country__c: pageLanguageCode.formLanguage,
    };
    /* eslint-enable camelcase */

    const [formValues, setFormValues] = useUTMFormValues(getInitialFormValues(components, initialFormValues));
    const notificationContext = useContext(NotificationContext);
    const [disableButton, setDisableButton] = useState(false);

    const formTranslations = useMemo(() => {
        return getTranslation(selectedLanguageTranslation, 'formTranslations');
    }, [selectedLanguageTranslation]);

    const submitButtonText = submitButton.text || formTranslations.submit;

    function updateFieldValue(component: FormComponent, value: string | boolean | number): void {
        const updatedValues = {
            [component.fieldId]: value,
        };

        if (component.conditionalFields) {
            component.conditionalFields.forEach((field) => {
                if (value !== field.value) {
                    field.components.forEach((fieldComponent) => {
                        if (formValues[fieldComponent.fieldId]) {
                            updatedValues[fieldComponent.fieldId] = '';
                        }
                    });
                }
            });
        }

        setFormValues((currentFormValues) => ({ ...currentFormValues, ...updatedValues }));
    }

    useEffect(() => {
        const componentsToDataLayer = components
            .filter((component) => 'toDataLayer' in component && component.toDataLayer)
            .map((component) => ({
                event: component.fieldId,
                [component.fieldId]: formValues[component.fieldId],
            }));
        setDataLayerFormValues(componentsToDataLayer);
    }, [components, formValues]);

    function handleMultiSelectChange(component: FormComponent, valueArray: string[]): void {
        updateFieldValue(component, valueArray.join(';'));
    }

    async function handleSubmit(event: FormEvent): Promise<void> {
        event.preventDefault();
        const validationResult = submitValidation(formSettings);

        if (validationResult.disableSubmit) {
            notificationContext.show(
                `One or more form settings not provided (${validationResult.errorFields.join(', ')})`,
                'error'
            );
        } else {
            setDisableButton(true);
            try {
                await postMarketingCloudForm(formId, formValues, token);
                redirectToThankyou(returnUrl, selectedLanguage, dataLayerFormValues);
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : error;
                notificationContext.show(errorMessage, 'error');
                refresh();
                setDisableButton(false);
            }
        }
    }

    return (
        <div css={{ width: '100%' }}>
            {title.length ? (
                <Grid item sm={12} css={classes.headingMargin}>
                    <Heading
                        variant='h2'
                        component={headerSection ? 'h1' : 'h2'}
                        align='center'
                        color={enableBackground ? 'inversePrimary' : 'primary'}
                    >
                        {sectionData.title}
                    </Heading>
                </Grid>
            ) : null}
            {subTitle.length ? (
                <Grid item sm={12} css={classes.headingMargin}>
                    <Text color={enableBackground ? 'inversePrimary' : 'primary'} align='center'>
                        {sectionData.subTitle}
                    </Text>
                </Grid>
            ) : null}
            <form
                method='POST'
                id={htmlFormId}
                css={classes.formBoxContainer}
                onSubmit={(event) => {
                    handleSubmit(event).catch((error) => logError(error, 'submitting custom form'));
                }}
                autoComplete={disableAutoFill ? 'off' : undefined}
                data-testid='customFormContentForm'
            >
                <ChildContainer breakSpaceValue={fieldSpacing}>
                    {components && components.length ? (
                        components.map((component) => {
                            return (
                                <React.Fragment key={JSON.stringify(component)}>
                                    <CreateCustomForm
                                        enableBackground={enableBackground}
                                        component={component}
                                        formValues={formValues}
                                        updateFieldValue={updateFieldValue}
                                        handleMultiSelectChange={handleMultiSelectChange}
                                        enableRecaptcha={enableRecaptcha}
                                        disableAutoFill={disableAutoFill}
                                    />
                                    {component.conditionalFields
                                        ? component.conditionalFields.map((field, fieldIndex) => {
                                            let showConditionalField = false;
                                            const componentValue = formValues[component.fieldId];

                                            if (
                                                component.name === 'dropdown' &&
                                                  component.selectType === 'multiple' &&
                                                  typeof componentValue === 'string' &&
                                                  typeof field.value === 'string' &&
                                                  getMultiSelectArray(componentValue).includes(field.value)
                                            ) {
                                                showConditionalField = true;
                                            } else if (componentValue === field.value) {
                                                showConditionalField = true;
                                            }

                                            if (showConditionalField) {
                                                return field.components.map((fieldComponent, index) => {
                                                    const fieldComponentCopy = { ...fieldComponent };
                                                    // ensure that the conditional field is set to the same breakpoint as the parent field
                                                    if (
                                                        !component.conditionalFieldsFixedBreakpoint &&
                                                          component.name === 'dropdown'
                                                    ) {
                                                        if (
                                                            fieldComponentCopy.name === 'dropdown' ||
                                                              fieldComponentCopy.name === 'textfield' ||
                                                              fieldComponentCopy.name === 'textarea'
                                                        ) {
                                                            fieldComponentCopy.breakpoint = component.breakpoint;
                                                        }
                                                    }

                                                    return (
                                                        <CreateCustomForm
                                                            key={`${fieldIndex}-${index}`}
                                                            enableBackground={enableBackground}
                                                            component={fieldComponentCopy}
                                                            formValues={formValues}
                                                            updateFieldValue={updateFieldValue}
                                                            handleMultiSelectChange={handleMultiSelectChange}
                                                            enableRecaptcha={enableRecaptcha}
                                                            disableAutoFill={disableAutoFill}
                                                        />
                                                    );
                                                });
                                            } else {
                                                return null;
                                            }
                                        })
                                        : null}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <Grid item sm={12} css={classes.noContentMargin}>
                            <Text
                                color={enableBackground ? 'inversePrimary' : 'primary'}
                                weight='bold'
                                data-testid='noComponentLabel'
                            >
                                Edit the section to configure the form
                            </Text>
                        </Grid>
                    )}
                    <Grid container item sm={12} justifyContent='flex-start'>
                        <Button
                            name='submit'
                            type='submit'
                            variant={submitButton.variant}
                            disabled={
                                disableButton ||
                                !(components && components.filter((comp) => comp.name !== 'heading').length) ||
                                shouldDisableSubmit(formValues, components)
                            }
                            fullWidth={submitButton.fullWidth}
                            css={classes.submitButton}
                            data-testid='submitButton'
                        >
                            {submitButtonText}
                        </Button>
                    </Grid>
                </ChildContainer>
            </form>
            {RecaptchaComponent}
        </div>
    );
}
