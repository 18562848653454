import { useState } from 'react';

interface UTMValues {
    /* eslint-disable camelcase */
    Lead_Source_URL__c: string;
    utm_source__c: string | null;
    utm_medium__c: string | null;
    utm_campaign__c: string | null;
    utm_content__c: string | null;
    utm_term__c: string | null;
    utm_id__c: string | null;
    GCLID__c: string | null;
    /* eslint-enable camelcase */
}

type UTMFormValues<FormValues extends Record<string, unknown>> = FormValues & UTMValues;

type UTMFormValuesReturnType<FormValues extends Record<string, unknown>> = [
    UTMFormValues<FormValues>,
    React.Dispatch<React.SetStateAction<UTMFormValues<FormValues>>>,
];

export const useUTMFormValues = <FormValues extends Record<string, unknown>>(
    initialFormValues: FormValues
): UTMFormValuesReturnType<FormValues> => {
    let utmSource: string | null = '';
    let utmTerm: string | null = '';
    let utmCampaign: string | null = '';
    let utmContent: string | null = '';
    let utmMedium: string | null = '';
    let utmID: string | null = '';
    let gclID: string | null = '';
    let leadSource = '';
    const utmParameters = getQuery();

    function getQuery(): UTMValues {
        if (typeof window !== 'undefined') {
            const query = new URLSearchParams(window.location.search);
            utmSource = query.get('utm_source');
            utmCampaign = query.get('utm_campaign');
            utmContent = query.get('utm_content');
            utmMedium = query.get('utm_medium');
            utmTerm = query.get('utm_term');
            utmID = query.get('utm_id');
            gclID = query.get('gclid');
            leadSource = window.location.href;
        }

        /* eslint-disable camelcase */
        return {
            Lead_Source_URL__c: leadSource,
            utm_source__c: utmSource,
            utm_medium__c: utmMedium,
            utm_campaign__c: utmCampaign,
            utm_content__c: utmContent,
            utm_term__c: utmTerm,
            utm_id__c: utmID,
            GCLID__c: gclID,
        };
        /* eslint-enable camelcase */
    }

    return useState({ ...initialFormValues, ...utmParameters });
};
