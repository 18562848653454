import { ContentSpacing } from '@models/section';

export interface FormComponentConditionalField {
    value: null | string | boolean;
    components: FormComponent[];
}

interface FormComponentBase {
    fieldId: string;
    conditionalFields?: FormComponentConditionalField[];
    conditionalFieldsFixedBreakpoint?: boolean;
}

type FormComponentBreakpoint = 4 | 6 | 8 | 12;

export interface FormBuilderTextField extends FormComponentBase {
    name: 'textfield';
    breakpoint: FormComponentBreakpoint;
    type: 'text' | 'number' | 'email' | 'tel';
    placeholder: string;
    defaultValue: string;
    required: boolean;
    label: string;
    toDataLayer?: boolean;
    hidden?: boolean;
}

export interface FormBuilderHiddenField extends FormComponentBase {
    name: 'hiddenfield';
    defaultValue: string;
    label: string;
    toDataLayer?: boolean;
}

export interface FormBuilderDropdown extends FormComponentBase {
    name: 'dropdown';
    breakpoint: FormComponentBreakpoint;
    type: DropdownType;
    selectType: 'single' | 'multiple';
    placeholder: string;
    defaultValue: string;
    required: boolean;
    sameOptionKeyValuePair: boolean;
    customOptions?: boolean;
    options?: { name: string; value: string }[] | null;
    label: string;
    toDataLayer?: boolean;
    forceMultiPick?: boolean;
}

export interface FormBuilderCheckbox extends FormComponentBase {
    name: 'checkbox';
    defaultValue: number;
    checkedToProceed: boolean;
    message: string;
    label: string;
    toDataLayer?: boolean;
}

export interface FormBuilderRadioButton extends FormComponentBase {
    name: 'radiobutton';
    defaultValue: number;
    yesToProceed: boolean;
    message: string;
    label: string;
    toDataLayer?: boolean;
}

export interface FormBuilderTextArea extends FormComponentBase {
    name: 'textarea';
    breakpoint: FormComponentBreakpoint;
    numberOfRows: string;
    placeholder: string;
    defaultValue: string;
    required: boolean;
    label: string;
    toDataLayer?: boolean;
}

export interface FormBuilderHeading extends FormComponentBase {
    name: 'heading';
    type: 'title' | 'subtitle' | 'text';
    weight: 'bold' | 'medium' | 'regular';
    italic: boolean;
    content: string;
    spacing: ContentSpacing;
    align: 'left' | 'center' | 'right';
}

export type FormComponent =
    | FormBuilderTextField
    | FormBuilderHiddenField
    | FormBuilderDropdown
    | FormBuilderCheckbox
    | FormBuilderRadioButton
    | FormBuilderTextArea
    | FormBuilderHeading;

export enum DropdownType {
    Country = 'country',
    Industry = 'industry',
    FleetSize = 'fleetSize',
    ClientType = 'clientType',
    AreaOfInterest = 'areaOfInterest',
    AreaOfInterest2 = 'areaOfInterest2',
    Custom = 'custom',
    CanadianProvince = 'province',
    AmericanState = 'state',
    BrazilianState = 'brazilianStates',
    Title = 'title',
    AdminService = 'adminService',
    Commerce = 'commerce',
    Construction = 'construction',
    Education = 'education',
    Entertainment = 'entertainment',
    Finance = 'finance',
    Government = 'government',
    Healthcare = 'healthcare',
    Hospitality = 'hospitality',
    Transportation = 'transportation',
    Utilities = 'utilities',
}
