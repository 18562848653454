import { FormComponent } from '@models/customForm';
import { capitalizeFirstLetter } from '@helpers/string';

export function getInitialFormValues(
    components: FormComponent[],
    initialFormValues: { [key: string]: string | boolean | number }
): { [key: string]: string | boolean | number } {
    let formValues = { ...initialFormValues };

    components.forEach((component) => {
        if (component.name !== 'heading') {
            if (component.name === 'checkbox' || component.name === 'radiobutton') {
                const value = {
                    [component.fieldId]: component.defaultValue === 1,
                };
                formValues = { ...formValues, ...value };
            } else {
                const value = {
                    [component.fieldId]: component.defaultValue,
                };
                formValues = { ...formValues, ...value };
            }
        }
    });

    return formValues;
}

export function getComponentTitle(component: FormComponent): string {
    return 'label' in component && component.label ? component.label : capitalizeFirstLetter(component.name);
}

export function submitValidation(formSettings: { [key: string]: string | boolean }): {
    disableSubmit: boolean;
    errorFields: string[];
} {
    const disableSubmit = !formSettings?.formId;
    const errorFields = [];

    if (!formSettings?.formId) errorFields.push('Marketing Cloud Form Id');

    return {
        disableSubmit: disableSubmit,
        errorFields: errorFields,
    };
}

export function getMultiSelectArray(value: string): string[] {
    if (value) {
        return value.split(';');
    }

    return [];
}

export function shouldDisableSubmit(
    formValues: { [x: string]: string | boolean | number | null },
    components: FormComponent[]
): boolean {
    const shouldDisable = components.some((component) => {
        const formValue = formValues[component.fieldId];

        switch (component.name) {
            case 'checkbox':
                return component.checkedToProceed && !formValue;
            case 'radiobutton':
                return component.yesToProceed && !formValue;
            case 'dropdown':
                if (component.selectType === 'multiple' && component.forceMultiPick) {
                    const multiSelectValue = typeof formValue === 'string' ? getMultiSelectArray(formValue) : [];
                    return multiSelectValue.length == 1;
                }
                return false;
            default:
                return false;
        }
    });

    return shouldDisable;
}

export const customFormTypographyType = [
    {
        value: 'title',
        content: 'Title',
    },
    {
        value: 'subtitle',
        content: 'Subtitle',
    },
    {
        value: 'text',
        content: 'Text',
    },
];

export const customFormTypographyWeightType = [
    {
        value: 'bold',
        content: 'Bold',
    },
    {
        value: 'medium',
        content: 'Medium',
    },
    {
        value: 'regular',
        content: 'Regular',
    },
];

export const customFormTypographyAlignType = [
    {
        value: 'left',
        content: 'Left',
    },
    {
        value: 'center',
        content: 'Center',
    },
    {
        value: 'right',
        content: 'Right',
    },
];
