/* eslint-disable @typescript-eslint/no-explicit-any */
export const trimObjectValues = (inputObject: Record<string, any>): Record<string, any> => {
    Object.keys(inputObject).forEach((i) => {
        const value = inputObject[i];
        if (typeof value === 'string') {
            inputObject[i] = value.trim();
        } else if (value === 'object') {
            if (value !== null && value !== undefined) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputObject[i] = trimObjectValues(value as Record<string, any>);
            } else {
                inputObject[i] = null;
            }
        }
    });

    return inputObject;
};
